
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import SettingsSection from '@/components/sections/dashboard/SettingsSection.vue'
import Main from '@/templates/Main.vue'

export default defineComponent({
  name: 'Settings',
  components: {
    Main,
    SettingsSection
  },
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
      // title: t('message.title.settings')
    })
  }
})
