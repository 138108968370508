
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import Select from '@/components/atoms/CustomSelect.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'SettingsSection',
  data () {
    return {
      loading: false,
      message: '',
      profileData: {},
      editTemplate: false,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      selectTitle: 'UA +380',
      countryCode: '',
      countryCodeData: {},
      data: [{}],
      errorSettings: {},
      isLoading: true
    }
  },
  components: {
    Form,
    ErrorMessage,
    Field,
    Select,
    AlertBlock,
    LottieAnimation: Vue3Lottie
  },
  watch: {
    phoneNumber: {
      deep: true,
      handler () {
        if (this.errorSettings) {
          this.errorSettings.phone = null
        }
      },
      immediate: true
    },
    lastName: {
      deep: true,
      handler () {
        if (this.errorSettings) {
          this.errorSettings.last_name = null
        }
      },
      immediate: true
    },
    firstName: {
      deep: true,
      handler () {
        if (this.errorSettings) {
          this.errorSettings.first_name = null
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getProfile()
    this.getCountryCodes()
  },
  methods: {
    reloadPage () {
      this.isLoading = true
      window.location.reload()
    },
    getProfile () {
      this.$store.dispatch('profile/getProfile').then(
        (res) => {
          this.profileData = res
          this.countryCode = this.profileData.phone.code
          this.phoneNumber = this.profileData.phone.number
          this.firstName = this.profileData.first_name
          this.lastName = this.profileData.last_name
          this.isLoading = false
        },
        (error) => {
          console.log(error)
          this.isLoading = false
        }
      )
    },
    getCountryCodes () {
      this.$store.dispatch('auth/getCountryCodes').then(
        (res) => {
          this.countryCodeData = res.data.map(({ country, code }) => ({
            title: `${country} ${code}`,
            value: code
          }))
          this.data = this.countryCodeData
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToEdit () {
      this.editTemplate = true
    },
    editProfile (data) {
      data.phone = this.countryCode + data.phone
      if (this.countryCode === '') {
        this.countryCode = '+380'
      }
      this.$store.dispatch('profile/editProfile', data).then(
        (res) => {
          console.log(res)
          this.editTemplate = false
          this.getProfile()
          this.errorSettings = null
        },
        (error) => {
          this.errorSettings = error.response.data.errors
        }
      )
    },
    handleOptionSelected (selectedOption) {
      this.countryCode = selectedOption.value
      this.errorSettings = null
    },
    hideError () {
      if (this.errorSettings) {
        this.errorSettings.phone = null
      }
    }
  }
})
