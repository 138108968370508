import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56f57140"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-section" }
const _hoisted_2 = { class: "settings-section__title" }
const _hoisted_3 = { class: "settings-section__title-group" }
const _hoisted_4 = { class: "settings-section__value-group" }
const _hoisted_5 = {
  key: 0,
  class: "settings-section__group-container"
}
const _hoisted_6 = { class: "settings-section__title-group" }
const _hoisted_7 = { class: "settings-section__value-group" }
const _hoisted_8 = { class: "settings-section__title-group" }
const _hoisted_9 = { class: "settings-section__value-group" }
const _hoisted_10 = { class: "settings-section__title-group" }
const _hoisted_11 = { class: "settings-section__value-group" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "form-group phone-code" }
const _hoisted_18 = {
  for: "phone",
  class: "form-group-label active"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "settings-section__button second-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LottieAnimation, {
          key: 0,
          animationData: require('@/assets/preloader/load.json')
        }, null, 8, ["animationData"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('message.settings.title')), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.email')), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(this.profileData?.email), 1),
      (!_ctx.editTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('message.settings.first_name')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(this.profileData?.first_name), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('message.settings.last_name')), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(this.profileData?.last_name), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('message.settings.phone_number')), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(this.profileData?.phone?.value), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_Form, { onSubmit: _ctx.editProfile }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Field, {
                    name: "first_name",
                    type: "text",
                    placeholder: _ctx.$t('message.settings.first_name'),
                    class: "form-control",
                    modelValue: this.firstName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.firstName) = $event))
                  }, null, 8, ["placeholder", "modelValue"]),
                  _createElementVNode("label", {
                    for: "first_name",
                    class: _normalizeClass(['form-group-label', (this.firstName + '').length > 0 && 'active'])
                  }, _toDisplayString(_ctx.$t('message.settings.first_name')), 3),
                  _createVNode(_component_ErrorMessage, {
                    name: "first_name",
                    class: "error-feedback"
                  }),
                  (this.errorSettings)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_AlertBlock, {
                          message: this.errorSettings.first_name
                        }, null, 8, ["message"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Field, {
                    name: "last_name",
                    type: "text",
                    placeholder: _ctx.$t('message.settings.last_name'),
                    class: "form-control",
                    modelValue: this.lastName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.lastName) = $event))
                  }, null, 8, ["placeholder", "modelValue"]),
                  _createElementVNode("label", {
                    for: "last_name",
                    class: _normalizeClass(['form-group-label', (this.lastName + '').length > 0 && 'active'])
                  }, _toDisplayString(_ctx.$t('message.settings.last_name')), 3),
                  _createVNode(_component_ErrorMessage, {
                    name: "last_name",
                    class: "error-feedback"
                  }),
                  (this.errorSettings)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_AlertBlock, {
                          message: this.errorSettings.last_name
                        }, null, 8, ["message"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_Select, {
                    data: this.data,
                    title: 'title',
                    onOptionSelected: _ctx.handleOptionSelected,
                    onClick: this.hideError,
                    "select-name": this.countryCode
                  }, null, 8, ["data", "onOptionSelected", "onClick", "select-name"]),
                  _createVNode(_component_Field, {
                    name: "phone",
                    type: "number",
                    class: "form-control settings-section__phone",
                    modelValue: this.phoneNumber,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.phoneNumber) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('message.settings.phone_number')), 1),
                  _createVNode(_component_ErrorMessage, {
                    name: "phone",
                    class: "error-feedback"
                  }),
                  (this.errorSettings)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createVNode(_component_AlertBlock, {
                          message: this.errorSettings.phone
                        }, null, 8, ["message"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("button", _hoisted_21, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.save')), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ])),
      (!_ctx.editTemplate)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "settings-section__button third-button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToEdit()))
          }, _toDisplayString(_ctx.$t('message.settings.edit')), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}